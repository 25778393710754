import React from 'react'
import { Cursor, useTypewriter } from 'react-simple-typewriter'
import GestureImg from '../../Data/Gamers.jpg'
const GestureForGamers = () => {
  return (
    <div>
        <header>
    <section>
      <div className='grid h-screen grid-cols-2'>
        <div className="">
        <div style={{margin:'50px'}} className='px-20'>
      <h1 className='text-purple-600 text-7xl'>Gesture for Gamers</h1>
      {/* <span style={{fontWeight:"bold"}} className='text-8xl text-purple-600'>
      {text}
      </span> */}
      <Cursor/>
      <p className='text-white text-xl px-5 mt-3'>Picture yourself seamlessly navigating virtual worlds, executing complex manoeuvres, and interacting with in-game elements—all with simple hand gestures. Our cutting-edge software for gamers will revolutionise the way you play, offering unprecedented freedom and flexibility in your gaming adventures.</p>
      <div className='px-5 mt-5'>
      {/* <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
        <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
          Learn More
        </span>
      </button> */}

      <button class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
        <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
          Download
        </span>
      </button>

      </div>
    </div>
        </div>

        <div className="">
        <div className='flex justify-center items-center'>
      <img className='w-[30rem] h-[25rem] mt-20 border-8 border-double border-purple-600 rounded-2xl' src={GestureImg} alt="Gesture" />
      </div>
        </div>

      </div>
    </section>
    </header>
    </div>
  )
}

export default GestureForGamers
